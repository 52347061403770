import { FormControl } from '@mui/material'
import CKAddressPicker from 'components/Form/CKAddressPicker'
import CKSelect from 'components/Form/CKSelect'
import { CKLoading } from 'components/Generic/CKLoading'
import MKBox from 'components/MaterialKit/MKBox'
import MKButton from 'components/MaterialKit/MKButton'
import MKTypography from 'components/MaterialKit/MKTypography'
import Address from 'models/Address'
import { FC, useCallback, useEffect, useState } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import UserService from 'services/UserService'

interface Props {}

const LocationSettings: FC<Props> = () => {
    const [address, setAddress] = useState<Address>(new Address())
    const [saving, setSaving] = useState<boolean>(false)
    const distances = [3, 5, 10, 15, 25, 50, 75]
    const [radius, setRadius] = useState(50)

    const {
        preferences,
        isLoading: isLoadingPreferences,
        isError: isErrorPreferences,
    } = UserService.usePreferences()
    const {
        address: fetchedAddress,
        isLoading: isLoadingAddress,
        isError: isErrorAddress,
    } = UserService.useAddress()

    useEffect(() => {
        if (fetchedAddress && !areAddressesEqual(fetchedAddress, address)) {
            console.log('Fetched address:', fetchedAddress)
            setAddress(fetchedAddress)
        }

        if (preferences && preferences.event_near_radius) {
            setRadius(preferences.event_near_radius)
        }
    }, [address, fetchedAddress, preferences])

    useEffect(() => {
        console.log('Address state updated:', address)
    }, [address])

    const save = useCallback(async () => {
        setSaving(true)

        try {
            console.log('Saving address:', address)
            await UserService.updateMyAddress(address)
            await UserService.updatePreferences({ event_near_radius: radius })
        } catch (e) {
            console.error(e)
        }

        setSaving(false)
    }, [address, radius])

    const renderRadiusSelector = () => (
        <FormControl fullWidth className="mt-3">
            <MKTypography variant="caption" color="text">
                Straal*:
            </MKTypography>
            <CKSelect
                variant="standard"
                options={distances.map((a) => {
                    return { value: a, label: '< ' + a + 'km' }
                })}
                defaultValue={radius}
                onSelect={(e) => {
                    const value: number = typeof e === 'string' ? parseInt(e) : e
                    setRadius(value)
                }}
            />
        </FormControl>
    )

    if (isLoadingPreferences || isLoadingAddress) return <CKLoading />
    if (isErrorPreferences || isErrorAddress) return <div>Error loading data</div>

    return (
        <>
            <MKTypography variant="body2" component="div" color="text" mb={3}>
                We gebruiken jouw locatie enkel om je meldingen te sturen als er evenementen in jouw
                buurt zijn.
            </MKTypography>
            {address && (
                <CKAddressPicker
                    label="Adres"
                    location={address}
                    disableFullWidth
                    onSelect={(props) => {
                        const newAddress = new Address()
                        Object.assign(newAddress, address)
                        newAddress.$lat = props.lat
                        newAddress.$lng = props.lng
                        newAddress.$fullAddress = props.address
                        newAddress.$city = props.city
                        newAddress.$postalCode = props.postal_code
                        newAddress.$street = props.street
                        newAddress.$streetNumber = props.street_number
                        newAddress.$region = props.region
                        console.log('New address selected:', newAddress)
                        setAddress(newAddress)
                    }}
                />
            )}
            {renderRadiusSelector()}
            <MKBox mt={4} mb={1}>
                <MKButton
                    variant="gradient"
                    color="info"
                    onClick={save}
                    fullWidth
                    disabled={saving}
                >
                    {saving ? <ClipLoader size={15} color={'white'} /> : 'Opslaan'}
                </MKButton>
            </MKBox>
        </>
    )
}

const areAddressesEqual = (address1: Address, address2: Address) => {
    return (
        address1.$lat === address2.$lat &&
        address1.$lng === address2.$lng &&
        address1.$fullAddress === address2.$fullAddress &&
        address1.$city === address2.$city &&
        address1.$postalCode === address2.$postalCode &&
        address1.$street === address2.$street &&
        address1.$streetNumber === address2.$streetNumber &&
        address1.$region === address2.$region
    )
}

export default LocationSettings